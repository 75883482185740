import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { NavigationService } from '@core/navigation/navigation.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const navigationService = inject(NavigationService);
    const _confirm = inject(FuseConfirmationService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
        if (navigationService._navigationMenuData.length == 0) {
            navigationService.getMenus();
        }
    }

    // Función para mostrar el diálogo de error
    const mostrarDialogoError = (error: HttpErrorResponse) => {

        // Extraer la descripción y los errores del resultado
        const descripcion = error.error?.result?.descripcion || 'Ocurrió un error desconocido.';
        const errores = error.error?.result?.errores || {};

        // Construir un mensaje de error detallado
        let mensajeError = `${error.message}\n${descripcion}`;
        for (const [campo, mensajes] of Object.entries(errores)) {
            // Asegúrate de que mensajes es un array de strings
            if (Array.isArray(mensajes)) {
                mensajeError += `\n${campo}: ${mensajes.join(', ')}`;
            }
        }

        _confirm.open({
            title: error.name + ' ' + error.status || 'Error',
            message: mensajeError,
            dismissible: false,
            actions: {
                confirm: {
                    show: true,
                    color: 'warn',
                    label: 'Confirmar'
                },
                cancel: {
                    show: false
                }
            }
        });
    };

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Capta errores desconocidos
            if (error.status === 0) {
                authService.signOut();
                location.reload();
                mostrarDialogoError(error);
                return throwError(() => new Error('Token expirado u inexistente'));
            }

            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                authService.signOut();
                location.reload();
                mostrarDialogoError(error);
                return throwError(() => new Error('Credenciales invalidas.'));
            }

            // Capta errores desconocidos
            if (error.status === 500) {
                mostrarDialogoError(error);
                return throwError(() => new Error('Error interno del servidor'));
            }

            if (error instanceof HttpErrorResponse && error.status === 404) {
                mostrarDialogoError(error);
                return throwError(() => new Error('Error en persistencia'));
            }

            // Mostrar diálogo para otros errores
            mostrarDialogoError(error);
        }),
    );
};
